/*
  This Component is created to show School Name and Pupil Name
  On Each report page
*/
import React, { memo } from "react";
import { useContext } from "react";
import PupilContext from "./context/PupilContext";
import { Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import { routeUrl } from "../Components/utils/RouteUrl";

const ComponentHeader = () => {
  const { pupilInfo,setActivePupilId,setSchoolsId,setSelectedPupilex,setselectedClient} = useContext(PupilContext);
  const history = useHistory();

  const UserId = localStorage.getItem('userRole')

  const HandleRoute = () => {
    //console.log()
    setActivePupilId(pupilInfo?.pupilId)
    setselectedClient(pupilInfo?.schoolId)
    setSelectedPupilex(true)

    history.push(routeUrl.attendanceCaller);
  };

  return (
    <div className="flex flex-col md:flex-row gap-5 school-pupil-table">
      <div className="flex items-center gap-1 school-pupil">
        <div className="school_label">
          <p>School Name</p>
        </div>
        <div className="school_input school-pupil-name">
          <h4>
            {pupilInfo && pupilInfo.schoolname
              ? pupilInfo.schoolname
              : "Not Selected"}
          </h4>
        </div>
      </div>
      <div className="flex flex items-center gap-1 school-pupil">
        <div className="school_label">
          <p>Pupil Name</p>
        </div>
        <div className="school_input school-pupil-name">
          <h4>
            {pupilInfo && pupilInfo.fullname
              ? pupilInfo.fullname
              : "Not Selected"}
          </h4>
        </div>
        {UserId == 12 ? (
          <div className="school_label">
          <Button
            type="primary"
            shape="round"
            className="attendance-redi"
            onClick={HandleRoute}
          >
            Go to Attendance
          </Button>
        </div>

        ):('')}
        
      </div>
    </div>
  );
};

export default memo(ComponentHeader);
